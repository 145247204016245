<template>
    <div>
        <div class="title main-between">
            <span class="title-left">{{ title }}</span>
        </div>
        <div class="add-user main-center">
            <el-form class="add-from" ref="addform" :model="adduserData" label-width="100px" :rules="rules" :hide-required-asterisk="true">
                <el-form-item label="账号" prop="account">
                    <el-input v-model="adduserData.account" placeholder="请填写账号"></el-input>
                </el-form-item>
                <el-form-item label="登录密码" prop="psw">
                    <el-input v-model="adduserData.psw" :show-password="true" placeholder="请填写密码"></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="marName">
                    <el-input v-model="adduserData.marName" placeholder="请填写用户名"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model.number="adduserData.phone" placeholder="请填写手机号"></el-input>
                </el-form-item>

                <el-form-item label="小程序数量" prop="app_num">
                    <el-input style="width: 75%" v-model.number="adduserData.app_num" :disabled="disablCal(cheAppnum)" placeholder="请填写可创建小程序数量">
                    </el-input>
                    <el-checkbox class="add-checkbox" v-model="cheAppnum">无限制</el-checkbox>
                </el-form-item>
                <el-form-item label="账户有效期" prop="time">
                    <el-date-picker
                        style="width: 75%"
                        v-model="adduserData.time"
                        :disabled="disablCal(cheTime)"
                        placeholder="请选择账户有效时间"
                        value-format="yyyy-MM-dd"
                    ></el-date-picker>
                    <el-checkbox class="add-checkbox" v-model="cheTime">永久</el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-button class="reset-btn" @click="reset">重置</el-button>
                    <el-button type="primary" @click="addUser">保存</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import rules from "@/utils/rules";
export default {
    props: {
        title: {
            type: String,
        },
    },
    data() {
        return {
            rules: rules,
            adduserData: {
                marName: "",
                phone: "",
                account: "",
                psw: "",
                app_num: "",
                time: "",
                auth: 1,
            },
            cheTime: false,
            cheAppnum: false,
        };
    },
    computed: {
        disablCal() {
            return function (select) {
                if (select) {
                    return true;
                } else {
                    return false;
                }
            };
        },
    },
    methods: {
        // 保存添加
        addUser() {
            const that = this;
            const { marName, phone, account, psw, app_num, time, auth } = this.adduserData;
            let data = {};
            that.$refs.addform.validate((valid) => {
                if (valid) {
                    if (app_num === "" && that.cheAppnum === false) {
                        that.$alert("小程序数量为必填", "", {});
                        return;
                    }
                    if (time == "" && that.cheTime === false) {
                        that.$alert("账户有效期必填", "", {});
                        return;
                    }

                    if (that.cheAppnum === true) {
                        if (that.cheTime === true && that.cheAppnum === true) {
                            data = { name: marName, phone, account, psw, app_num: -1, time: "永久", auth };
                        } else if (that.cheTime === true) {
                            data = { name: marName, phone, account, psw, app_num, time: "永久", auth };
                        } else if (that.cheAppnum === true) {
                            data = { name: marName, phone, account, psw, app_num: -1, time, auth };
                        }
                    } else {
                        data = { name: marName, phone, account, psw, app_num, time, auth };
                    }

                    that.$request({
                        url: that.$api.merchantMent.addUser,
                        method: "post",
                        data: data,
                    }).then((res) => {
                        that.$message({
                            message: "添加成功",
                            type: 'success'
                        });
                        that.$router.push('/erchantMent/list')
                    });
                }
            });
        },
        // 重置
        reset() {
            this.adduserData = {
                marName: "",
                phone: "",
                account: "",
                psw: "",
                app_num: "",
                time: "",
                auth: 1,
            };
            this.cheTime = false;
            this.cheAppnum = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.title {
    height: 50px;
    border-bottom: 1px solid #999999;

    .title-left {
        font-size: 20px;
        font-weight: 600;
    }
}

.add-user {
    margin-top: 70px;

    .add-from {
        width: 50%;

        .reset-btn {
            background-color: #f5f6fa;
            color: #999999;
            margin-right: 24px;
        }

        .add-checkbox {
            margin-left: 40px;
        }
    }
}
</style>
