<template>
    <div class="main-pdding">
        <div class="main-box">
            <addSub  title="新增子账户信息"></addSub>
        </div>
    </div>
</template>

<script>
    import addSub from './addSub.vue'
    export default {
        components:{
            addSub
        },
        data(){
            return{
                addUserData:{
                    name:'',
                    phone:'',
                    account:'',
                    psw:'',
                    app_num:'',
                    time:'',
                    auth:''
                }
            }
        },
        methods:{
        }
    }
</script>

<style lang="scss" scoped>

</style>